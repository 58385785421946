<template>
  <a-card :bordered="false" class="m-b-10">
    <a-button size='small' @click="()=>{$router.go(-1)}">
      <LeftOutlined />返回
    </a-button>
    <span class="ml15 card_title">反馈详情</span>
  </a-card>
  <a-card :bordered="false">
    <div>
      <p class="s_c333  s_lin_h35">反馈者：{{feedbackData.feedback_name}}</p>
      <p class="s_c333  s_lin_h35">端口： {{feedbackData.port_name}}</p>
      <p class="s_c333  s_lin_h35">经销商/门店名称：{{feedbackData.store_name}}</p>
      <p class="s_c333  s_lin_h35">反馈类型：{{feedbackData.type}}</p>
      <p class="s_c333  s_lin_h35">问题描述：</p>
      <p class="s_c333 width70">{{feedbackData.content}}</p>
      <p class="s_c333  s_lin_h35">图片：{{image.length ? '':'暂无图片'}}</p>
      <p class="width70">
        <a-image
          class="p-r-10"
          v-for='item in image' :key='item'
          :width="150"
          :height="150"
          :src="info.picdomain+item"
        />
      </p>
      <p class="s_c333  s_lin_h35">联系电话：{{feedbackData.phone}}</p>
      <p class="s_c333  s_lin_h35">反馈时间：{{feedbackData.create_time}}</p>
      <p class="s_c333  s_lin_h35">
        状态：
        <a-radio-group v-model:value="radioType">
          <a-radio :value="0">普通反馈</a-radio>
          <a-radio :value="1">星标反馈</a-radio>
        </a-radio-group>
      </p>
    </div>
    <div class="m-t-25">
      <a-button class="width-120 m-r-20" block @click="()=>{$router.go(-1)}">返回</a-button>
      <a-button class="width-120" block type="primary" @click="onSubmit">保存</a-button>
    </div>
  </a-card>
</template>

<script>
import { ref, onMounted, inject, reactive, toRefs } from "vue";
import { LeftOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { getFeedbackMore,feedbackEdit } from "@/api/index";
import { $iscode } from "@/utils/app";
import store from "@/store";
export default {
  components: { LeftOutlined },
  setup() {
    const state = reactive({
      info: store.state.user.info,
      radioType:0,
      feedbackData:{},
      image:[]
    });
    const $route = inject("$route");
    const $router = inject("$router");
    //获取详情
    const initData = async () => {
      const hide = message.loading("正在加载中...", 0);
      let res = await getFeedbackMore({ id: $route.query.id }).then((res) => res.data);
      state.listLoading = false;
      if ($iscode(res)) {
        state.feedbackData = res.data;
        state.radioType = res.data.status
        state.image = res.data.image
      } else {
        message.error(res.msg);
      }
      setTimeout(hide);
    };
    //提交
    const onSubmit = async() => {
      const hide = message.loading("正在加载中...", 0);
      let res = await feedbackEdit({id: $route.query.id,status:state.radioType}).then((res) => res.data);
      if ($iscode(res)) {
        message.success(res.msg);
        setTimeout(() => {
          $router.go(-1);
        }, 2000);
      } else {
        message.error(res.msg);
      }
      setTimeout(hide);
    };
    onMounted(() => {
      initData();
    });
    return {
      ...toRefs(state),
      onSubmit
    };
  },
};
</script>
<style>
  .ant-image{
    overflow: hidden;
  }
</style>