//首页统计api

import axios from '@/utils/request'
// 报表 - 统计
export const getStatisticsIndex = (params)=>{
 return axios.request({
    url: 'statistics/index',
    method: 'get',
    params
  })
};
// 排行 - 首页多个排行
export const getRankIndex = (params)=>{
  return axios.request({
     url: 'rank/index',
     method: 'get',
     params
   })
 };
 // 排行 - 应用排行获取更多
export const getRankSysApps = (params)=>{
  return axios.request({
     url: 'rank/sysAppsSalesRank',
     method: 'get',
     params
   })
 };
 // 排行 - 短信排行获取更多
export const getRankSms = (params)=>{
  return axios.request({
     url: 'rank/smsSalesBrandRank',
     method: 'get',
     params
  })
};
/**
 * 意见反馈
 */

//意见反馈列表
export const getFeedbackList = (params)=>{
  return axios.request({
    url: 'feedback/index',
    method: 'get',
    params
  })
};
//意见反馈详情
export const getFeedbackMore = (params)=>{
  return axios.request({
    url: 'feedback/detail',
    method: 'get',
    params
  })
};
//意见反馈修改
export const feedbackEdit = (data)=>{
  return axios.request({
   url: 'feedback/update',
   method: 'post',
   data
   })
 };